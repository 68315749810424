<template>
    <div class="exam-root">
    <div class="exam-top">
      <img src="../../assets/arrow_circle_back.png" style="cursor: pointer;width: 74px;height: auto" @click="goBack">
      <div style="margin-left: 50px;text-align: left">
        <div style="color: white;font-size: 30px;font-weight: 600;font-family: Montserrat-SemiBold;">
          2021-2022 FBLA China<br>
          National Leadership Conference and Economics Challenge
        </div>
      </div>
    </div>
    <div class="exam-content">
      <div class="awardDetail">


          <div class="awardTitle">Congratulations</div>

                    <div class="scoreLab2">{{'/   ' + data.full_score}}</div>
                    <div class="scoreLab1">{{data.total_score}}</div>
          <div class="awardTitle1">{{data.application_event}}</div>


          <div class="awardLine"></div>

          <div class="awardSubTitle">You have learnt and mastered the following knowledge, concepts and theories:</div>

          <div class="box">

            <div v-for="(item,index) in data.achievements" :key="index" class="child">
                <img class="infoImg" src="../../assets/selectInfoImage.png">
                <div class="infoStyle">{{'&nbsp;&nbsp;'+ item + '&nbsp;&nbsp;'}}</div>
               
            </div>

          </div>
          


          <div @click="goBack"  class="returnStyle">Return Home</div>

          



      </div>
    </div>
  </div>

</template>

<script>
import {getGroupAwards,getAwards,getMyAward} from '../../api/index'
import {getUserId} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            events:[
                'Artithon - Academic Marathon in Art',
                'Biothon - Academic Marathon in Biology',
                'Chemithon - Academic Marathon in Chemistry',
                'Econthon - Academic Marathon in Economics',
                'Geothon - Academic Marathon in Geography',
                'Historithon - Academic Marathon in History',
                'Mathethon - Academic Marathon in Mathematics',
                'Physithon - Academic Marathon in Physics',
                'Psychothon - Academic Marathon in Psychology'
            ],
            currentEvent:'Artithon - Academic Marathon in Art',
            groupAwards:[],
            personalAwards:[],
            myAward:{
            },
            show:false,
            showGroup:false,
            showCom:false,

            awardId:'',
            data:{}
        }
    },
    mounted(){
        this.awardId = this.$route.query.awardId
        this.data = this.$route.query.data

        // this.getGroupAwards()
        // this.getAwards()
        // this.getMyAward()
    },
    methods:{
        handleEventName(event){
            if (event == undefined){
                return ''
            }
            let event_array = event.split(" ")
            console.log(111,event,event_array)
            return event_array[0]
        },

        goBack() {
            this.$router.go(-1)
        },
        getAwards(){
            getAwards('21-22FBLAbiz_china','Business Communication').then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.personalAwards = res.data.data
                }
            })
        },
        getMyAward(){
            getMyAward('6158c9dba4640b2c518b4573').then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.myAward = res.data.data
                }
            })
        },
        getGroupAwards(){
            getGroupAwards('21-22FBLAbiz_china','Business Communication').then(res=>{
                if(res.data.code===0){
                    this.groupAwards = res.data.data
                }
            })
        },
        chooseEvent(item){
            this.currentEvent = item
            this.getGroupAwards()
            this.getAwards()
        },
        upfirstName(name){
            let transferName = name.toLowerCase();
            return transferName.charAt(0).toUpperCase() + transferName.slice(1)
        },
        rank(number){
            if(number===1){
                return 'st';
            }else if(number===2){
                return 'nd';
            }else if(number===3){
                return 'rd';
            }else{
                return 'th'
            }
        },
        printPersonal(){
            let url = this.$router.resolve({
                path:'/blank'
            })
            let newWindow=window.open(url.href,"_blank");//打印窗口要换成页面的url
            let obj = document.getElementById('cerP')

            let docStr = obj.innerHTML;
            
            newWindow.document.write(docStr);
            
            newWindow.document.close();
            
            newWindow.print();
            
            newWindow.close();
            
        }
    }
}
</script>

<style lang="less">
.el-dialog{
    width:320mm;
}
.exam-content {
  margin-top: -1px;
  width: 100%;
  background-image: url("../../assets/exam-content-bg.png");
  height: 82vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  padding: 30px 9vw;
  overflow-x: hidden;
}
.awardDetail{
    background-image: url("../../assets/awardDetailBg.png");
    width: 90%;
    height: 70vh;
    margin-left: 5%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    position:relative;
    font-family: Montserrat-SemiBold;
}
.awardTitle{
    font-weight: bold;
    text-align: center;
    color: #0F429E;
    font-size: 60px;
    margin-top: 30px;
    font-family: Montserrat-SemiBold;
}
.awardTitle1{
    font-weight: bold;
    text-align: left;
    color: #0036A0;
    font-size: 30px;
    margin-top: 60px;
    margin-left: 80px;
    font-family: Montserrat-SemiBold;
}
.awardLine{
    background-color: #00359F;
    margin-left: 80px;
    margin-right: 80px;
    height: 1px;
    margin-top: 10px;
    font-family: Montserrat-SemiBold;
   
}
.scoreLab1{
  margin-top: 60px;   
  margin-right: 10px;
  height: 40px;
//   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px; 
  color: #FD5900;
  float:right;
  font-family: Montserrat-SemiBold;

}

.scoreLab2{
  margin-top: 65px;   
  margin-right: 80px;  
  height: 40px;
//   background-color: rebeccapurple;
  text-align: right;
  color: #0F429E;
  font-size: 25px;
  float:right;
  font-family: Montserrat-SemiBold;
}
.awardSubTitle{
    text-align: left;
    color: #2D50B0;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 80px;
    font-family: Montserrat-SemiBold;
}
.returnStyle{
    position: absolute;
    // display: flex;
    // margin-bottom: 10px;
    // align-items: center;
    bottom: 40px;
    margin-left: 45%;
    font-weight: 600;
    width: 140px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    // align-content: center;
    // align-items: center;
    border-radius: 15px;
    background-color: #2D50B0;
    color: white;
    font-family: Montserrat-SemiBold;
}
.box{
    display: flex;
    justify-content: left;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 20px;
    height: 30vh;
    // background-color: #FD5900;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: left;
    align-content: left;
    text-align: left;
    float: left;
}
.child{
    // width: 50px;
    // background-color: aqua;
    height: 30px;
    padding-left: 10px;
}
.infoStyle{
    display: flex;
    border: 1px solid #2D50B0;
    border-radius: 16px;
    color: #2D50B0;
    margin-left: 20px;
    margin-top: -22px;
    font-family: Montserrat-SemiBold;
}
.infoImg{
    margin-top: 10px;
    width: 14px;
    height: 14px;
}
.exam-top {
  background-image: url("../../assets/topBanner.png");
  height: 18vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9vw;
}


.exam-root {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

</style>